/**
 * Endpoints: searchSeats, searchBags
 * 
 * Search functionality for seats or bags.
 * Given tripId, tripAccessToken, Gordian API v2 is called and json results are polled.
 */
import { logger } from './logger';
import { trackEvent } from './tracking';
import { Itinerary, TripCreationToken, GordianJourney, Passenger, Orders, IdentityDocument } from './types';
import { getGlobalObject } from './utils';

const GORDIAN_API_URL = "https://api.gordiansoftware.com/v2.2"

export async function getTrip(tripId: string | undefined, tripAccessToken: string | undefined) {
    const headers: { [key: string]: string } = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${tripAccessToken}`
    }

    const response = await fetch(
        `${GORDIAN_API_URL}/trip/${tripId}`,
        { method: 'GET', headers }
    )

    const responseJson = await response.json();
    if (!response.ok) {
        const { status, statusText } = response
        const error = new Error(`Status code ${status}, ${statusText}, ${JSON.stringify(responseJson)})`)
        logger.error(error)
        logger.sentryError(error)
        throw new Error(`Status code ${status}, ${statusText}`)
    }

    try {
        const { trip_state_hash, trip_id, trip_access_token, integration, organization, journeys, orders, passengers }: {
            trip_state_hash: string,
            trip_id: string,
            trip_access_token: string,
            integration: string,
            organization: string,
            journeys: GordianJourney[],
            orders: Orders,
            passengers: Passenger[],
        } = responseJson
        return {
            trip_state_hash,
            trip_id,
            trip_access_token,
            integration,
            organization,
            journeys,
            orders,
            passengers
        }
    } catch {
        const error = new Error("An error occurred when parsing the response for product")
        logger.error(error)
        logger.sentryError(error)
        throw error
    }

}


export function searchSeats(tripId: string, tripAccessToken: string | undefined, existingSearchId: string | undefined, searchParams: {} | undefined) {
    // console.log('searching for seats trip ID: ' + tripId)
    return search("seat", tripId, tripAccessToken, existingSearchId, searchParams);
}

export function searchBags(tripId: string, tripAccessToken: string | undefined, existingSearchId: string | undefined, searchParams: {} | undefined) {
    // console.log('searching for bags trip ID: ' + tripId)
    return search("bag", tripId, tripAccessToken, existingSearchId, searchParams);
}

async function search(ancillary_type: ("seat" | "bag"), tripId: string, tripAccessToken: string | undefined, existingSearchId: string | undefined, searchParams: {} | undefined) {
    let search_id: string;
    const mixpanelProperties = { trip_id: tripId, productType: ancillary_type, existingSearchId: !!existingSearchId }

    trackEvent(`SDK Create Search`, mixpanelProperties)

    if (existingSearchId) {
        logger.log(`Using existing ${ancillary_type} search with ID ${existingSearchId}`)
        search_id = existingSearchId
    } else {
        const response = await fetch(
            `${GORDIAN_API_URL}/trip/${tripId}/search`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${tripAccessToken}`
                },
                body: JSON.stringify({ seat: { search: ancillary_type === "seat", ...searchParams }, bag: { search: ancillary_type === "bag", ...searchParams } })
            }
        )

        const responseJson = await response.json();
        if (!response.ok) {
            const { status, statusText } = response
            const error = new Error(`Status code ${status}, ${statusText}, ${JSON.stringify(responseJson)})`)
            logger.error(error)
            logger.sentryError(error)
            trackEvent('SDK Create Search Error', { ...mixpanelProperties, error: statusText })
            throw new Error(`Status code ${status}, ${statusText}`)
        }
        search_id = responseJson.search_id

    }
    logger.sentryTag(`${ancillary_type} search ID`, search_id)
    let searchResponse;

    const headers: { [key: string]: string } = {
        Authorization: `Bearer ${tripAccessToken}`
    }

    searchResponse = await poll(() => {
        return fetch(
            `${GORDIAN_API_URL}/trip/${tripId}/search/${search_id}${ancillary_type === 'seat' ? '?raw=TRUE' : ''}`,
            { method: 'GET', headers }
        );
    }, 100000, 1000);

    try {
        checkProductStatus(searchResponse, ancillary_type)
        trackEvent('SDK Search Pull Success', mixpanelProperties)
        if (ancillary_type === 'bag') {
            return searchResponse
        }
        const { raw_response } = searchResponse;
        const { display_json } = raw_response;

        // If the search from v2 doesn't pass through v1, then then the raw search response should already be in the correct format, so just return that
        if (!display_json) {
            return searchResponse
        }
        const { availability_v2_json } = display_json;
        return availability_v2_json
    } catch {
        const error = new Error(`An error occurred when parsing the search response for ${ancillary_type}`)
        logger.error(error)
        logger.sentryError(error)
        trackEvent('SDK Search Pull Error', mixpanelProperties)
        throw error
    }

}

async function poll(fn: any, timeout: number = 2000, interval: number = 100) {
    const endTime = Number(new Date()) + (timeout);
    const checkCondition = (resolve: (arg0: any) => void, reject: (arg0: Error) => void) => {
        const fetch = fn();
        fetch.then(async (data: any) => {
            const response = await data.json();
            const { status } = response;
            if (Number(new Date()) >= endTime) {
                const error = new Error(`Timed out for ${JSON.stringify(fn)}: ${timeout} ${interval}`)
                logger.error(error)
                logger.sentryError(error)
                return reject(error);
            }
            switch (status) {
                case 'in_progress': {
                    if (Number(new Date()) < endTime) {
                        return setTimeout(checkCondition, interval, resolve, reject);
                    }
                    return;
                }
                case 'success':
                    return resolve(response);
                case 'failed':
                    return reject(new Error("Search returned status 'failed'"));
                default:
                    break;
            }
        });
    };
    return new Promise(checkCondition);
}

export async function createTrip(itinerary: Itinerary, tripCreationToken: TripCreationToken) {
    const body = _createTripReqBody(itinerary)
    const response = await fetch(
        `${GORDIAN_API_URL}/trip`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${tripCreationToken}`
            },
            body: JSON.stringify(body)
        }
    )

    if (!response.ok) {
        const { status, statusText } = response
        const responseJson = await response.json()
        const error = new Error(`Status code ${status}, ${statusText}, ${JSON.stringify(responseJson)})`)
        logger.error(error)
        logger.sentryError(error)
        throw new Error(`Status code ${status}, ${statusText}`)
    }

    try {
        const body = await response.json()
        const { trip_state_hash, trip_id, trip_access_token, search_id, integration, organization }: {
            trip_state_hash: string,
            trip_id: string,
            trip_access_token: string,
            search_id: string,
            integration: string,
            organization: string,
        } = body
        return {
            trip_state_hash,
            trip_id,
            trip_access_token,
            search_id,
            integration,
            organization,
        }
    } catch {
        const error = new Error("An error occurred when parsing the response for product")
        logger.error(error)
        logger.sentryError(error)
        throw error
    }

}

function checkProductStatus(searchResponse: any, product: string) {
    if (searchResponse.product_status?.[product]?.status === "failed") {
        const { status, errors } = searchResponse.product_status[product];
        const errorMessage = errors.reduce((prev: string, curr: string) => prev + "; " + curr, "");
        throw new Error(`${product} status = ${status}. Reason: ${errorMessage}`)
    }
}

export async function postIdentityDocuments(
    tripId: string,
    tripAccessToken: string,
    passengerId: string,
    identityDocument: IdentityDocument
) {
    const global = getGlobalObject<Window>();
    const callbackSaveIdentityDoc = global.__GORDIAN__.eventCallbacks?.onSaveIdentityDocument;
    if (callbackSaveIdentityDoc) {
        callbackSaveIdentityDoc(tripId, passengerId, identityDocument);
    }

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${tripAccessToken}`
    };

    const response = await fetch(
        `${GORDIAN_API_URL}/trip/${tripId}/passenger/${passengerId}/identity_document`,
        {
            method: 'POST',
            headers,
            body: JSON.stringify([identityDocument])
        }
    );

    const responseJson = await response.json();
    if (!response.ok) {
        const { status, statusText } = response
        const error = new Error(`Status code ${status}, ${statusText}, ${JSON.stringify(responseJson)})`)
        logger.error(error)
        logger.sentryError(error)
        const callbackSaveIdentityDocFail = global.__GORDIAN__.eventCallbacks?.onSaveIdentityDocumentFail;
        if (callbackSaveIdentityDocFail) {
            callbackSaveIdentityDocFail(tripId, passengerId, identityDocument, {
                statusCode: status,
                type: "SAVE_IDENTITY_DOCUMENT_ERROR",
                message: statusText
            });
        }
        throw new Error(`Status code ${status}, ${statusText}`)
    }
    return responseJson;
}

export async function deleteIdentityDocument(tripId: string, tripAccessToken: string, passengerId: string, identityDocumentId: string) {
    const global = getGlobalObject<Window>();
    const callbackDeleteIdentityDoc = global.__GORDIAN__.eventCallbacks?.onDeleteIdentityDocument;
    if (callbackDeleteIdentityDoc) {
        callbackDeleteIdentityDoc(tripId, passengerId, identityDocumentId);
    }

    const headers: { [key: string]: string } = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${tripAccessToken}`
    }

    const response = await fetch(
        `${GORDIAN_API_URL}/trip/${tripId}/passenger/${passengerId}/identity_document/${identityDocumentId}`,
        { method: 'DELETE', headers }
    )

    const responseJson = await response.json();
    if (!response.ok) {
        const { status, statusText } = response
        const error = new Error(`Status code ${status}, ${statusText}, ${JSON.stringify(responseJson)})`)
        logger.error(error)
        logger.sentryError(error)
        const callbackDeleteIdentityDocFail = global.__GORDIAN__.eventCallbacks?.onDeleteIdentityDocumentFail;
        if (callbackDeleteIdentityDocFail) {
            callbackDeleteIdentityDocFail(tripId, passengerId, identityDocumentId, {
                statusCode: status,
                type: "DELETE_IDENTITY_DOCUMENT_ERROR",
                message: statusText
            });
        }
        throw new Error(`Status code ${status}, ${statusText}`)
    }
    return responseJson;
}

function _createTripReqBody(itinerary: Itinerary) {
    const searches = itinerary.searches.map(search => ({ [search]: true }))
    const tickets = itinerary.tickets.map(ticket => ({
        status: ticket.status,
        supplier_account_id: ticket.supplierAccountId,
        journeys: ticket.journeys,
        skip_basket_check: false,
        access_details: ticket.accessDetail || undefined
    }))

    const passengers = itinerary.passengers.map((pax) => ({
        type: pax.type,
        first_names: pax.firstNames,
        surname: pax.surname,
    }))


    const body = {
        searches,
        tickets,
        language: itinerary.language,
        country: itinerary.country,
        currency: itinerary.currency,
        passengers
    }

    return body
}
