import { GordianJourney, Orders } from './types';

export class NoAciAvailableError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'NoAciAvailableError';
    }
}

export class CheckInWindowError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'CheckInWindowError';
    }
}

export function validateACITrip(tripJson: { orders: Orders, journeys: GordianJourney[] }) {
    const aciOrders = Object.values(tripJson.orders || {})
        .filter(order => order.product_type === 'automatic_check_in');

    if (aciOrders.length === 0) {
        throw new NoAciAvailableError('No automatic check-ins are associated with this trip');
    }

    if (!aciOrders.every(order => order.status === 'fulfilled')) {
        throw new NoAciAvailableError('Automatic check-in is not ready for execution');
    }

    const now = new Date();
    const hasNotDeparted = tripJson.journeys
        .flatMap(journey => journey.segments)
        .every(segment => now < new Date(segment.departure_time));

    if (!hasNotDeparted) {
        throw new CheckInWindowError('Check in window has passed.');
    }
}
