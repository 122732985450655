/**
 * Trip is a unique session with a basket for selling products.
 * Each trip is identified with tripId and a tripAccessToken
 */

import { Options, SearchIds, TripFetchOptions, GordianJourney, GordianSegment } from './types'
import { getGlobalObject, GordianGlobal } from './utils';
import { logger } from './logger';
import { createTrip, getTrip } from "./api"
import { BasketValidator } from './basketValidator';

/**
 * Representation of a trip:
 *  id - tripId
 *  tripAccessToken
 *  onBasketChange
 */
export class Trip {
    id: string;
    tripAccessToken: string;
    onBasketChange: (() => void);
    searchIds: SearchIds | undefined;
    integration: string;
    organization: string;
    carriers: string[];

    constructor(tripOptions: Options) {
        const { tripId, tripAccessToken, searchIds } = tripOptions;
        if (!tripId || !tripAccessToken) {
            throw new Error('Missing tripId and tripAccessToken ')
        }

        logger.sentryContext("tripOptions", tripOptions);
        logger.sentryTag("trip", tripId)
        this.id = tripId
        this.tripAccessToken = tripAccessToken
        this.searchIds = searchIds
        this.onBasketChange = tripOptions.onBasketChange
        this.integration = tripOptions.integration ?? ""
        this.organization = tripOptions.organization ?? ""
        this.carriers = []
    }
}

/**
 * Initializes a new trip and binds it to __GORDIAN__ object on global.
 * (optional) Initializes callbacks in the trip context if provided.
 * Note: If a trip already exists, it will be replaced with the new trip.
 *
 * @returns the newly created trip object.
 */
export function createTripObject(options: Options): Trip {
    const carrier = getMainCarrier();
    carrier.__GORDIAN__.trip = new Trip(options);
    carrier.__GORDIAN__.searchParams = options?.searchParams ? options?.searchParams : {} 
    // callbacks
    carrier.__GORDIAN__.eventCallbacks = options?.eventCallbacks
    if (options.eventCallbacks?.onInvalidBasket) {
        carrier.__GORDIAN__.basketValidator = new BasketValidator();
    }
    return carrier?.__GORDIAN__?.trip;
}

export function exactractCarriersFromJourney(journeys: GordianJourney[]): string[]{
    const allCarriers = new Set<string>();

    journeys.forEach((journey: GordianJourney) => {
        const segments = journey.segments
        segments.forEach((segment: GordianSegment) => {
            allCarriers.add(segment.marketing_airline)
        })
    })
    return Array.from(allCarriers)
}

export async function updateTripFromV2(options: TripFetchOptions) {
    if (!options.tripId) throw new Error("Trip ID must be set")
    if (!options.tripAccessToken) throw new Error("Trip Access Token Must be set must be set")
    const tripFromApi = await getTrip(options.tripId, options.tripAccessToken)

    const { journeys } = tripFromApi
    const allCarriers: string[] = exactractCarriersFromJourney(journeys)

    const carrier = getMainCarrier();
    carrier.__GORDIAN__.trip.integration = tripFromApi.integration
    carrier.__GORDIAN__.trip.organization = tripFromApi.organization
    carrier.__GORDIAN__.trip.carriers = allCarriers
    return tripFromApi
}

export async function createTripObjectFromItinerary(options: Options): Promise<Trip> {
    if (!options.tripCreationToken) throw new Error("Trip creation token must be set")
    if (!options.itinerary) throw new Error("Itinerary must be set")
    const tripFromApi = await createTrip(options.itinerary, options.tripCreationToken)
    const searchIds: SearchIds = {seat: undefined, bag: undefined}

    for (const search of options.itinerary.searches) {
        if (search === "seat" || search === "bag") {
            searchIds[search] = tripFromApi.search_id
        } else {
            throw new Error("invalid search type")
        }
    }

    return createTripObject(
       {
        ...options,
        tripAccessToken: tripFromApi.trip_access_token,
        tripId: tripFromApi.trip_id,
        integration: tripFromApi.integration,
        organization: tripFromApi.organization,
        searchIds
       } 
    )
}


/**
 * @returns The current trip that is bound on __GORDIAN__
 */
export function getCurrentTrip(): Trip {
    const registry = getMainCarrier();
    logger.sentryContext('Get Current Trip: registry', registry)
    return getTripFromCarrier(registry);
}

/**
 * @returns the GordianGlobal object that holds the binding to __GORDIAN__
 */
function getMainCarrier(): GordianGlobal {
    const carrier = getGlobalObject<Window>();
    carrier.__GORDIAN__ = carrier.__GORDIAN__ || {
        logger: undefined,
        trip: undefined,
    };
    return carrier;
}

/**
 * @param carrier GordianGlobal object with binding to __GORDIAN__
 * @returns Trip binding on the (GordianGlobal -> __GORDIAN__) object.
 * @throws Error if invald binding. Trip needs to be properly initialized.
 */
function getTripFromCarrier(carrier: GordianGlobal): Trip {
    if (carrier && carrier.__GORDIAN__ && carrier.__GORDIAN__.trip) {
        return carrier.__GORDIAN__.trip;
    }
    throw new Error('No Option provided to create Trip')
}
