import { BrowserOptions, Upsell, TripFetchOptions } from './types'
import { Trip, getCurrentTrip, updateTripFromV2, createTripObjectFromItinerary, createTripObject } from './trip';
import { getUpsell, toggleModal } from './upsell';
import { getSeats } from './seats';

import { logger } from './logger'
import { getGlobalObject } from './utils';
import { trackEvent } from './tracking';

/**
 * @group main
 * @param options - options to pass to to initialized the widget
 */
export function init(options: BrowserOptions): void {
    const trackingProperties = {
        trip_id: options.tripId,
        has_trip_creation_token: !!options.tripCreationToken,
    }

    try {
        logger.sentryContext("Init", options)
        if (options.debug) logger.enable();
        if (options.tripId && options.tripAccessToken) {
            createTripObject(options)
            trackEvent('SDK Initialized', trackingProperties)
        }
        else if (options.tripCreationToken) {
            createTripObjectFromItinerary(options).then(trip => {
                trackingProperties["trip_id"] = trip.id
                trackEvent('SDK Initialized', trackingProperties)
            }).catch(e => {
                trackEvent('SDK Initialization Failed', trackingProperties)
                logger.error("init error", e)
            })
        } else {
            throw new Error(
                "Must set at least trip id and trip access token or trip creation token"
            )
        }
    } catch (e) {
        trackEvent('SDK Initialization Failed', trackingProperties)
        logger.error("init error", e)
    }

}


/**
 * Reveals the upsell widget to the user
 * @group main
 * @param _configuration - Upsell configuration options 
 * @returns 
 */
export async function showUpsell(_configuration: Upsell): Promise<any> {
    try {
        const trip = getCurrentTrip()
        const tripFetchOption: TripFetchOptions = { ...trip, tripId: trip.id }
        const tripData = await updateTripFromV2(tripFetchOption)
        const trackingParameters = { trip_id: trip.id }
        logSentryContext("showUpsell", _configuration, trip)
        trackEvent("SDK Show Upsell", trackingParameters)
        await getUpsell(trip, _configuration, tripData)
    } catch (e) {
        logger.error(e)
        logger.sentryError(e as Error)
    }
    return Promise.resolve()
}

export async function showSeats(_configuration: Upsell): Promise<any> {
    try {
        const trip = getCurrentTrip();
        logSentryContext("showSeats", _configuration, trip)
        await getSeats(trip, _configuration)
    } catch (e) {
        logger.error(e)
        logger.sentryError(e as Error)
    }
    return Promise.resolve()
}

export function showModal(show: Boolean) {
    toggleModal(show)
}

/**
 * @group helpers
 * @returns 
 */
export function validateBasket() {
    try {
        const global = getGlobalObject<Window>()
        const basketValidator = global.__GORDIAN__.basketValidator
        if (basketValidator) {
            const isValid = Object.keys(basketValidator.removed).length === 0
            return { basket: basketValidator.basket, removed: basketValidator.removed, isValid: isValid }
        } else {
            throw Error("No basket validation defined. Ensure the onInvalidBasket callback is defined")
        }
    } catch (e) {
        logger.error(e)
        logger.sentryError(e as Error)
        throw e
    }
}

/**
 * @group helpers
 * @returns 
 */
export function formatPrice(price: number, decimalPlaces: number, currency: string): string {
    const displayPrice: number = price / Math.pow(10, decimalPlaces);
    return new Intl.NumberFormat("en", {
        style: "currency",
        currency
    }).format(displayPrice);
};

function logSentryContext(name: string, _configuration: Upsell, trip: Trip) {
    try {
        logger.sentryContext(name, { _configuration: JSON.stringify(_configuration), trip: JSON.stringify(trip) })
    } catch (e) {
        logger.sentryContext(name, { _configuration, trip })
        const error = e as Error
        logger.sentryContext("sentryError", { error: error?.message })
    }

}
