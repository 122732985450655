/**
 * Calls the seatmap repo for static js and css assets.
 * Utilities to inject the received assets into document head.
 */

import { logger } from './logger'
import { getGlobalObject } from './utils'

/**
 * 
 * @param product ancillary type. eg - "seats", "bags", etc.
 * @param url base url for assets
 * @returns {asset: endpoints, url: base_url used for fetching}
 */
export async function getAssetManifest(product: string, url?: string): Promise<any> {
    switch (product) {
        case 'seats':
            const BASE_URL = url ?? 'https://static.gordiansoftware.com'
            const MANIFEST_URL = `${BASE_URL}/asset-manifest.json`
            const response = await fetch(MANIFEST_URL)
            const json = await response.json()
            return { assets: json, url: BASE_URL };
        default:
            break;
    }
    return Promise.resolve()
}

export function loadAssets(assetManifest: any, url: string) {
    const global = getGlobalObject<Window>()
    if (global.gordianInternal === undefined) {
        logger.log("loading assets");
        return Promise.all(
            assetManifest.entrypoints.map((entrypoint: string) => {
                return loadAsset(entrypoint, url);
            })
        );
    }
    return Promise.resolve()
}

/**
 * takes path to a css or js file, creates a link/src, attaches to head of document.
 * @param assetPath - source on server at url
 * @param url - base url
 * @returns 
 */
export function loadAsset(assetPath: string, url: string): Promise<void> {
    let asset: any = document.createElement('script');

    const URL = `${url}/${assetPath}`;
    logger.log(`loading asset ${URL}`);

    if (URL.match(/\.css$/i)) {
        asset = document.createElement('link');
        asset.rel = "stylesheet";
        asset.href = URL;
    } else if (URL.match(/\.js$/i)) {
        asset.type = "text/javascript";
        asset.src = URL;
    } else {
        logger.error(`Not sure how to interpret url ${URL}`);
    }

    // TODO: Ask why there are 2 injections
    document.getElementsByTagName('head')[0].appendChild(asset);

    return new Promise((resolve) => {
        if (asset.readyState) {
            asset.onreadystatechange = () => {
                if (asset.readyState === 'loaded' || asset.readyState === 'complete') {
                    asset.onreadystatechange = null;
                    resolve();
                }
            };
        } else {
            asset.addEventListener('load', () => {
                resolve();
            });
        }

        // TODO: Ask why there are 2 injections
        document.getElementsByTagName('head')[0].appendChild(asset);
    })
}
